<div class="stepper">
    <mat-horizontal-stepper
        #stepper
        [linear]="true"
        (selectionChange)="onStepChange($event.selectedStep.label)">
        <ng-template
            matStepperIcon="edit"
            let-index="index">
            {{ index + 1 }}
        </ng-template>
        <mat-step
            [completed]="false"
            [label]="Stepper.ConnectYourFeed">
            <connect-feed />
        </mat-step>
        <mat-step
            [completed]="false"
            [label]="Stepper.SelectTemplate">
            <templates />
        </mat-step>
        <mat-step
            [completed]="false"
            [label]="Stepper.SelectFields">
            <ng-template matStepContent>
                <div class="tw-mx-20">
                    <select-fields />
                </div>
            </ng-template>
        </mat-step>
        @if (showSelectCreativeStep$ | async) {
            <mat-step [label]="Stepper.ConnectCreative">
                <ng-template matStepContent> <default-creative-card /></ng-template>
            </mat-step>
        }
        <mat-step [label]="Stepper.CustomiseYourFeed">
            <ng-template matStepContent>
                <customise-feed />
            </ng-template>
        </mat-step>
    </mat-horizontal-stepper>
</div>

import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { userResolver } from './account.resolver';
import { TemplistComponent } from './templist/templist.component';
import { FeedEditorComponent } from './views/feed-editor/feed-editor.component';
import { StepperComponent } from './views/stepper/stepper.component';

export const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuard],
        resolve: { user: userResolver },
        children: [
            { path: '', component: StepperComponent },
            { path: 'list', component: TemplistComponent },
            { path: 'feed-editor/:id', component: FeedEditorComponent }
        ]
    }
];

<div>
    <div
        class="tw-w-[286px] tw-p-4 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-mb-8 tw-bg-white">
        <h1>8</h1>
        <p>source fields selected</p>
        <p>HomeTeam, AwayTeam, OddsHome, OddsDraw, OddsAway, Sports, League, HomeShirt, AwayShirt</p>
    </div>

    <div
        class="tw-w-[286px] tw-p-4 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-mb-8 tw-bg-white">
        <h1>108</h1>
        <p>products fetched</p>
        <p>Really? wow so cool</p>
    </div>

    <div
        class="tw-w-[286px] tw-p-4 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-mb-8 tw-bg-white">
        <h1>0</h1>
        <p>no sub-feeds connected</p>
        <p>why? come on connect some sub-feeds</p>
    </div>

    <div
        class="tw-w-[286px] tw-p-4 tw-px-8 tw-border tw-border-solid tw-border-ui-grey-89 tw-rounded-md tw-mb-8 tw-bg-white">
        <h1>0</h1>
        <p>no creative sets connected</p>
        <p>Take my money</p>
    </div>
</div>

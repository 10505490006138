import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RowEvent, UIColumnDef, UIModule, UITableDataSource } from '@bannerflow/ui';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  pairwise,
  startWith,
  switchMap
} from 'rxjs';
import { InfiniteScrollDirective } from 'src/app/directives/infinite-scroll.directive';
import { CreativeSetListItem, ListItemType, Path } from 'src/app/models/creative-set-folders.model';
import { CreativeItem } from 'src/app/models/creative.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { ListService } from 'src/app/services/api/list.service';

type CreativeListData = {
    searchInputValue: string;
    columnNames: UIColumnDef[];
    dataSource: UITableDataSource<CreativeSetListItem>;
    pagination: { page: number; pageSize: number };
    totalItems: number;
    breadCrumbs: { folderId: string; folderName: string }[];
};
@Component({
    selector: 'creative-list',
    standalone: true,
    imports: [CommonModule, FormsModule, MatInputModule, UIModule, InfiniteScrollDirective],
    templateUrl: './creative-list.component.html',
    styleUrl: './creative-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreativeListComponent {
    @Output() creativeSetChange = new EventEmitter<CreativeItem>();

    private listService = inject(ListService);
    private accountAccessService = inject(AccountAccessService);
    private _folderIdSubject = new BehaviorSubject<string>('root');
    private _searchSubject = new BehaviorSubject<string>('');
    private _loading$ = new BehaviorSubject<boolean>(false);
    private _pagination$ = new BehaviorSubject<{ page: number; pageSize: number }>({ page: 1, pageSize: 10 });

    private dataSource = new UITableDataSource<CreativeSetListItem>();
    loading$ = this._loading$.asObservable();

    creativeListData$: Observable<CreativeListData> = combineLatest([
        this._folderIdSubject.asObservable().pipe(pairwise(), startWith(['root', 'root'])),
        this._searchSubject.pipe(debounceTime(300), distinctUntilChanged(), pairwise(), startWith(['', ''])),
        this._pagination$,
        this.accountAccessService.user$.pipe(map(user => user.brand.id))
    ]).pipe(
        switchMap(([[prevFolderId, currentFolderId], [prevSearch, currSearch], pagination, brandId]) => {
            this._loading$.next(true);
            if (prevSearch !== currSearch && currSearch !== '') {
                // reset page number when search changes
                pagination.page = 1;
            }

            const apiCall = currSearch
                ? this.listService.getFilteredCreativesetAndFolders(
                      currSearch,
                      pagination.page,
                      pagination.pageSize,
                      brandId
                  )
                : this.listService.getCreativesetsandFolders(
                      pagination.page,
                      pagination.pageSize,
                      currentFolderId,
                      brandId
                  );

            return apiCall.pipe(
                map(creativeSetsAndFolders => {
                    if (currSearch !== prevSearch || prevFolderId !== currentFolderId) {
                        this.dataSource.setData(creativeSetsAndFolders.items);
                    } else {
                        this.dataSource.addData(creativeSetsAndFolders.items);
                    }
                    this._loading$.next(false);
                    return {
                        pagination,
                        totalItems: creativeSetsAndFolders.totalItemsCount,
                        searchInputValue: currSearch,
                        columnNames: [
                            { columnDef: 'name', name: 'Name', isCustom: true },
                            { columnDef: 'modifiedAt', name: 'Modified', width: '20%' }
                        ],
                        dataSource: this.dataSource,
                        breadCrumbs: this.generateBreadcrumbs(creativeSetsAndFolders.path)
                    };
                })
            );
        })
    );

    loadMore(currentPagination: { page: number; pageSize: number }, totalItems: number) {
        if (currentPagination.page * currentPagination.pageSize >= totalItems) {
            return;
        }
        this._pagination$.next({ page: currentPagination.page + 1, pageSize: currentPagination.pageSize });
    }

    breadcrumbSelected(folderId: string) {
        this._folderIdSubject.next(folderId);
    }

    searchedText(value: string) {
        this._searchSubject.next(value);
    }

    folderClicked(event: RowEvent<CreativeSetListItem>) {
        if (event.row.type === ListItemType.Folder) {
            this._folderIdSubject.next(event.row.id);
        } else {
            if (event.row.externalId) {
                const creativeSetChange = { id: event.row.externalId, name: event.row.name };
                this.creativeSetChange.emit(creativeSetChange);
            }
        }
    }

    private generateBreadcrumbs(paths: Path[]): { folderId: string; folderName: string }[] {
        return [
            { folderId: 'root', folderName: 'All' },
            ...paths.map(path => ({ folderId: path.id, folderName: path.name }))
        ];
    }
}

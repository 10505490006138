import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Stepper } from 'src/app/models/stepper.model';

type StepperNavigation = 'previous' | 'next';
@Injectable({
    providedIn: 'root'
})
export class StepperService {
    private _currentStep = new BehaviorSubject<string>(Stepper.ConnectYourFeed);
    currentStep$ = this._currentStep.asObservable();
    private _stepCompleted = new Subject<void>();
    stepCompleted$ = this._stepCompleted.asObservable();
    private _stepperNavigation$ = new Subject<StepperNavigation>();
    stepperNavigation$ = this._stepperNavigation$.asObservable();

    completeStep(): void {
        this._stepCompleted.next();
    }

    previousStep(): void {
        this._stepperNavigation$.next('previous');
    }

    nextStep(): void {
        this._stepperNavigation$.next('next');
    }

    setCurrentStep(step: string): void {
        this._currentStep.next(step);
    }
}

<div
    class="tw-bg-gray-200 tw-w-screen tw-h-full tw-flex tw-items-center tw-flex-col tw-gap-4 tw-p-[25px] tw-px-[30px] tw-rounded-t-[50px] tw-shadow-[0_10px_20px_rgba(0,0,0,0.03)]">
    <div
        class="tw-flex tw-justify-center"
        (mousedown)="dragStart()">
        <div class="tw-cursor-grab tw-select-none tw-p-4">
            <span class="tw-h-1 tw-w-10 tw-block tw-bg-gray-400 tw-rounded-full"></span>
        </div>
    </div>
    <ng-content></ng-content>
</div>

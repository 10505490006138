import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { UIModule } from '@bannerflow/ui';
import { combineLatest } from 'rxjs';
import { Stepper } from 'src/app/models/stepper.model';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { DraggableBottomSheetService } from 'src/app/services/ui/draggable-bottom-sheet.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { DefaultCreativeCardComponent } from '../../shared/default-creative-card/default-creative-card.component';
import { ConnectFeedComponent } from './connect-feed/connect-feed.component';
import { TemplatesComponent } from './connect-feed/templates/templates.component';
import { CustomiseFeedComponent } from './customise-feed/customise-feed.component';
import { SelectFieldsComponent } from './select-fields/select-fields.component';
@Component({
    selector: 'stepper',
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        MatButtonModule,
        MatStepperModule,
        ConnectFeedComponent,
        CustomiseFeedComponent,
        SelectFieldsComponent,
        MatIconModule,
        TemplatesComponent,
        DefaultCreativeCardComponent,
        BottomNavigationComponent
    ],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false }
        }
    ],
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
    @ViewChild('stepper') stepper: MatStepper;
    private stepperService = inject(StepperService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private draggableBottomSheetService = inject(DraggableBottomSheetService);
    showSelectCreativeStep$ = this.mainFeedFieldsService.typeCreativeFieldSelected$;

    Stepper = Stepper;

    constructor() {
        this.handleStepCompletion();
        this.handleBottomSheetVisibility();
        this.handleNavigationSteps();
    }

    handleBottomSheetVisibility(): void {
        const bottomSheetCreated$ = this.draggableBottomSheetService.bottomSheetCreated$;
        const currentStep$ = this.stepperService.currentStep$;

        combineLatest([bottomSheetCreated$, currentStep$])
            .pipe(takeUntilDestroyed())
            .subscribe(([bottomSheetCreated, currentStep]) => {
                if (bottomSheetCreated) {
                    if (currentStep !== Stepper.CustomiseYourFeed) {
                        this.draggableBottomSheetService.hideBottomSheet();
                    } else {
                        this.draggableBottomSheetService.showBottomSheet();
                    }
                } else {
                    return;
                }
            });
    }

    handleStepCompletion(): void {
        const stepCompleted$ = this.stepperService.stepCompleted$;
        stepCompleted$.pipe(takeUntilDestroyed()).subscribe(() => {
            if (this.stepper.selected) {
                this.stepper.selected.completed = true;
            }
            this.stepper.next();
        });
    }

    onStepChange(stepLabel: string): void {
        this.stepperService.setCurrentStep(stepLabel);
    }

    handleNavigationSteps(): void {
        this.stepperService.stepperNavigation$.pipe(takeUntilDestroyed()).subscribe(navigation => {
            if (navigation === 'previous') {
                this.stepper.previous();
            } else {
                this.stepper.next();
            }
        });
    }
}

import { Component, inject, Input, ViewChild } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
import { CreativeSelectorComponent } from '../creative-selector/creative-selector.component';
import { BottomNavigationComponent } from '../bottom-navigation/bottom-navigation.component';
import { StepperService } from 'src/app/services/ui/stepper.service';

@Component({
    selector: 'default-creative-card',
    standalone: true,
    imports: [UIModule, MatInputModule, MatFormFieldModule, CreativeSelectorComponent, CreativePreviewComponent, BottomNavigationComponent],
    templateUrl: './default-creative-card.component.html',
    styleUrl: './default-creative-card.component.scss'
})
export class DefaultCreativeCardComponent {
    @Input() creativeField: string = 'image_link';
    @ViewChild('selectCreativeDialog') selectCreativeDialog: UIDialogDirective;
    stepperService = inject(StepperService);

    selectedCreative: Creative;
    inputValue = '';

    openCreativeSelector(event: MouseEvent): void {
        event.stopPropagation();
        this.selectCreativeDialog.open();
    }

    onCreativeSelected(creative: Creative  | null): void {
        this.selectedCreative = creative!;
        this.inputValue = `${creative!.size.width} x ${creative!.size.height}`;
    }
}

<div
    #previewContainer
    class="tw-w-[300px] tw-aspect-square">
    @if (selectedCreative) {
        <div
            #container
            class="tw-my-auto tw-mx-auto tw-pointer-events-none"
            [style]="{ width: newCreativeWidth + '%' }"></div>
    }
</div>

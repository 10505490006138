import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UIModule } from '@bannerflow/ui';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { CreativeSelectorComponent } from 'src/app/shared/creative-selector/creative-selector.component';

@Component({
    selector: 'connect-feed',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        UIModule,
        RouterModule,
        CreativeSelectorComponent
    ],
    templateUrl: './connect-feed.component.html',
    styleUrls: ['./connect-feed.component.scss']
})
export class ConnectFeedComponent {
    private feedSourceService = inject(FeedSourceService);
    private stepperService = inject(StepperService);
    private authService = inject(AuthService);

    inputValue = 'https://bffeedsprocessorlocal.blob.core.windows.net/feeds/iGaming-MRES.json';

    connectFeed(): void {
        if (this.inputValue) {
            this.feedSourceService.setFeedSourceUrl(this.inputValue);
            this.stepperService.completeStep();
        }
    }

    logout(): void {
        this.authService.logout();
    }
}

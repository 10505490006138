<ui-header [full]="true"
    ><div class="header-content">
        <ui-svg-icon
            class="icon"
            [icon]="'log'"
            [routerLink]="['/list']"></ui-svg-icon>
        @if (environment.stage === 'sandbox') {
            <sandbox-pr-slots></sandbox-pr-slots>
        }</div
></ui-header>
<ui-body
    [full]="true"
    class="ui-body">
    <router-outlet></router-outlet>
</ui-body>

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, combineLatest, distinctUntilChanged, switchMap } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { GenericFeedField } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MainFeedFieldsService {
    private _fields$ = new BehaviorSubject<GenericFeedField[]>([]);
    private http = inject(HttpClient);
    fields$ = this._fields$.asObservable();
    private _typeCreativeFieldSelected$ = new BehaviorSubject<boolean>(false);
    typeCreativeFieldSelected$ = this._typeCreativeFieldSelected$.asObservable()

    setCreativeFieldType( isCreative: boolean): void {
        this._typeCreativeFieldSelected$.next(isCreative);
    }

    setFields(fields: GenericFeedField[]): void {
        this._fields$.next(fields);
    }

    getSuggestedFields(
        feedSourceUrl: Observable<string>,
        template: Observable<Template>
    ): Observable<GenericFeedField[]> {
        return combineLatest([template, feedSourceUrl]).pipe(
            distinctUntilChanged(([prevTemplate, prevFeedSourceUrl], [currTemplate, currFeedSourceUrl]) => {
                return prevTemplate.id === currTemplate.id && prevFeedSourceUrl === currFeedSourceUrl;
            }),
            switchMap(([template, feedSourceUrl]) => {
                const endpoint = template.useAI
                    ? `${environment.origins.feedsSupremeCommander}/api/MainFeeds/blueprintWithAiSuggestions`
                    : `${environment.origins.feedsSupremeCommander}/api/MainFeeds/blueprint`;

                const payload = template.useAI
                    ? { templateId: template.id, sourceFeedUrl: feedSourceUrl }
                    : { templateId: template.id };

                return this.http.post<GenericFeedField[]>(endpoint, payload);
            })
        );
    }
}

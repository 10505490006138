import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute } from '@angular/router';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { BehaviorSubject, Observable, combineLatest, map, of, shareReplay, switchMap, take } from 'rxjs';
import { Filter, FilterGroup } from 'src/app/models/filters.model';
import { OperationsSummary, OperationsType } from 'src/app/models/operations.model';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { CardSummaryComponent } from 'src/app/shared/card-summary/card-summary.component';
import { OperationsSummaryComponent } from 'src/app/shared/operations-summary/operations-summary.component';
import { readableUpdateIntervals } from 'src/app/utils/main-feed-helper';
import { FeedItem, FeedUpdateInterval, FeedValueObject, MainFeed } from '../../models/feed.model';
import { MainFeedService } from '../../services/api/main-feed.service';
import { PreviewCarouselComponent } from './preview-carousel/preview-carousel.component';
@Component({
    selector: 'feed-editor',
    standalone: true,
    templateUrl: './feed-editor.component.html',
    styleUrl: './feed-editor.component.scss',
    imports: [
        CommonModule,
        UIModule,
        CardSummaryComponent,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        PreviewCarouselComponent,
        OperationsSummaryComponent
    ]
})
export class FeedEditorComponent {
    private activatedRoute = inject(ActivatedRoute);
    private mainFeedService = inject(MainFeedService);
    private mainFilterService = inject(MainFilterService);
    private previewService = inject(PreviewService);
    private notificationService = inject(UINotificationService);

    isFieldValueEmpty = false;
    private updatedInterval$ = new BehaviorSubject<FeedUpdateInterval | null>(null);

    previewData$: Observable<{ [key: string]: FeedValueObject }[]> = this.fetchPreviewData();
    operationsSummary$: Observable<OperationsSummary[]> = this.fetchOperationsSummary();
    mainFeedById$: Observable<MainFeed> = combineLatest([this.fetchMainFeedById(), this.updatedInterval$]).pipe(
        map(([mainFeed, updatedInterval]) =>
            updatedInterval ? { ...mainFeed, updateInterval: updatedInterval } : mainFeed
        )
    );
    feedEditorData$ = this.feedEditorData();
    readableUpdateIntervals$ = of(readableUpdateIntervals());

    fetchPreviewData() {
        return this.activatedRoute.params.pipe(
            switchMap(params => this.fetchData(params['id'])),
            map(previewData => previewData.data.map((item: FeedItem) => item.data))
        );
    }

    fetchOperationsSummary() {
        return this.activatedRoute.params.pipe(
            switchMap(params => this.fetchData(params['id'])),
            map(({ filters }) => this.mapFiltersToOperationsSummary(filters))
        );
    }

    fetchMainFeedById() {
        return this.activatedRoute.params.pipe(
            switchMap(params => this.mainFeedService.getMainFeedById(params['id'])),
            shareReplay(1)
        );
    }

    feedEditorData() {
        return combineLatest([this.mainFeedById$, this.previewData$, this.operationsSummary$]).pipe(
            map(([mainFeed, previewData, operationsSummary]) => ({
                mainFeed,
                previewData,
                operationsSummary
            }))
        );
    }

    updateInterval(mainFeed: MainFeed, updatedInterval: FeedUpdateInterval) {
        const updatedFeed = { ...mainFeed, updateInterval: updatedInterval };
        this.mainFeedService
            .updateMainFeed(updatedFeed)
            .pipe(take(1))
            .subscribe(() => {
                this.updatedInterval$.next(updatedInterval);
                this.notificationService.open('Feed interval updated', {
                    type: 'success',
                    autoCloseDelay: 3000,
                    placement: 'top'
                });
            });
    }

    private fetchData(id: string) {
        return combineLatest([
            this.mainFeedService.getMainFeedById(id),
            this.mainFilterService.getFiltersById(id)
        ]).pipe(
            switchMap(([mainfeed, filters]) => {
                const previewDataRequest = {
                    id: mainfeed.id,
                    source: mainfeed.sourceUrl,
                    fields: mainfeed.blueprint.fields,
                    filters
                };
                return this.previewService
                    .getPreviewData(previewDataRequest)
                    .pipe(map(previewData => ({ data: previewData.data, filters })));
            })
        );
    }

    private mapFiltersToOperationsSummary(filters: FilterGroup[]): OperationsSummary[] {
        return filters.map(filter => ({
            label: filter.label || '',
            type: OperationsType.Filter,
            summary: this.generateSummary(filter)
        }));
    }

    private generateSummary(filterGroup: FilterGroup): string[] {
        return filterGroup.filters.map(
            (filter: Filter) =>
                `<div>Filter: <strong>${this.capitalizeFirstLetter(filter.field)}</strong> field ${filter.operator.toLowerCase()} <strong>"${filter.arguments.toString()}"</strong></div>`
        );
    }

    private capitalizeFirstLetter(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }
}

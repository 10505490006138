import { Injectable, inject } from '@angular/core';
import { PublishCatalogRequest } from '../../models/catalog.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

type CatalogResponse = { catalogUrl: string };

@Injectable({
    providedIn: 'root'
})
export class CatalogService {
    http = inject(HttpClient);

    createCatalog(catalogRequest: PublishCatalogRequest): Observable<CatalogResponse> {
        return this.http.post<CatalogResponse>(
            `${environment.origins.feedsSocializingMaster}/api/Meta/catalog/publish`,
            catalogRequest
        );
    }
}

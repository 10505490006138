import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericFeedField, PreviewData } from '../../models/feed.model';
import { FilterGroups } from '../../models/filters.model';
import { Observable } from 'rxjs';

interface PreviewDataRequest {
    id?: string;
    source: string;
    fields: GenericFeedField[];
    filters?: FilterGroups;
}

@Injectable({
    providedIn: 'root'
})
export class PreviewService {
    private http = inject(HttpClient);

    getPreviewData(request: PreviewDataRequest): Observable<PreviewData> {
        return this.http.post<PreviewData>(`${environment.origins.feedsFearsomeProcessor}/Preview`, {
            ...request
        });
    }
}

<form
    [formGroup]="filterForm"
    class="arraygroup-wrapper">
    <ng-container formArrayName="arrayGroups">
        @for (filterGroup of filterForm.controls.arrayGroups.controls; track filterGroup; let i = $index) {
            <div
                class="filter-card"
                [formGroupName]="i">
                <div class="card-header">
                    <editable-field [formCtrl]="filterGroup.controls.label">
                        <p class="tw-font-normal tw-text-sm">{{ filterGroup.controls.label.value }}</p>
                    </editable-field>
                    @if (!cardOpenStatus[i]) {
                        <filter-summary [filterGroup]="filterGroup"></filter-summary>
                    }
                    <div class="action">
                        <ui-svg-icon
                            [icon]="cardOpenStatus[i] ? 'arrow-up' : 'arrow-down'"
                            (click)="toggleCard(i)"
                            class="icon">
                        </ui-svg-icon>
                        <ui-svg-icon
                            [icon]="'delete'"
                            (click)="removeFilterGroup(i)"
                            class="icon">
                        </ui-svg-icon>
                    </div>
                </div>
                @if (cardOpenStatus[i]) {
                    <div class="tw-flex tw-flex-row tw-gap-4 tw-items-start tw-mt-5">
                        <p class="tw-text-sm tw-whitespace-nowrap tw-flex tw-items-center tw-my-5">I want to</p>
                        <mat-form-field
                            appearance="outline"
                            class="tw-w-full tw-max-w-xs">
                            <mat-select
                                placeholder="Select action"
                                #actionSelect
                                hideSingleSelectionIndicator>
                                <mat-select-trigger>
                                    <ui-svg-icon [icon]="actionSelect.value?.icon"></ui-svg-icon>&nbsp;
                                    {{ actionSelect.value?.label }}
                                </mat-select-trigger>
                                @for (action of actionOptions; track action) {
                                    <mat-option
                                        class="tw-flex tw-items-center tw-gap-1"
                                        [value]="action">
                                        <ui-svg-icon [icon]="action.icon" />
                                        &nbsp;{{ action.label }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>

                        <div
                            class="grid-column tw-gap-4 tw-items-center tw-w-full"
                            formGroupName="filters">
                            @for (filter of filterGroup.controls.filters.controls; track filter; let j = $index) {
                                <ng-container [formGroupName]="j">
                                    @if (j === 0) {
                                        <field-select
                                            class="tw-w-[320px]"
                                            [fieldFormControl]="filter.controls.field"
                                            type="Path"
                                            [label]="'Select field'" />

                                        <p class="tw-text-sm tw-whitespace-nowrap tw-flex">so it</p>
                                        <mat-form-field
                                            appearance="outline"
                                            class="tw-w-full tw-max-w-xs tw-h-[57px]">
                                            <mat-select
                                                placeholder="Select operator"
                                                formControlName="operator"
                                                hideSingleSelectionIndicator
                                                dynamicDropdown>
                                                @for (operator of operators$ | async; track operator) {
                                                    <mat-option [value]="operator.value">{{
                                                        operator.label
                                                    }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                        <p class="tw-text-sm tw-flex">following</p>
                                    }
                                    <div
                                        class="tw-flex tw-flex-col tw-w-full"
                                        [ngClass]="{ 'tw-col-start-5 tw-col-end-5': j > 0 }">
                                        <ng-container formGroupName="arguments">
                                            @for (
                                                argument of filter.controls.arguments.controls;
                                                track argument;
                                                let k = $index
                                            ) {
                                                <div class="tw-flex tw-items-center tw-justify-center tw-gap-4">
                                                    <mat-form-field
                                                        appearance="outline"
                                                        class="tw-w-full">
                                                        <input
                                                            matInput
                                                            [formControlName]="k"
                                                            placeholder="Type to start selecting..." />
                                                    </mat-form-field>
                                                    <div class="tw-flex tw-gap-4 tw-items-center">
                                                        <ui-button
                                                            type="default"
                                                            svgIcon="plus-small"
                                                            [disabled]="
                                                                filterGroup.invalid ||
                                                                !(j === filterGroup.controls.filters.length - 1) ||
                                                                !(k === filter.controls.arguments.length - 1)
                                                            "
                                                            (click)="addFilter(filterGroup, j)"></ui-button>
                                                        <ui-button
                                                            type="default"
                                                            svgIcon="minus-small"
                                                            [disabled]="filterGroup.controls.filters.length === 1"
                                                            (click)="removefilter(filterGroup, j)"></ui-button>
                                                    </div>
                                                </div>
                                            }
                                        </ng-container>
                                    </div>
                                </ng-container>
                            }
                        </div>
                    </div>
                }
                @if (cardOpenStatus[i]) {
                    <div class="radio-button">
                        <mat-radio-group formControlName="filterType">
                            <mat-radio-button value="Include">include</mat-radio-button>
                            <mat-radio-button value="Exclude">exclude</mat-radio-button>
                            <ng-content select="[radio-button]"></ng-content>
                        </mat-radio-group>
                    </div>
                }
            </div>
        }
    </ng-container>
</form>
<div class="button">
    <ui-button
        type="default"
        svgIcon="plus-small"
        class="add-filter-button"
        [border]="false"
        [text]="'NEW MAIN FILTER'"
        [disabled]="filterForm.invalid"
        (click)="addGroupFilter()"></ui-button>
    <ui-button
        type="primary"
        text="APPLY FILTER"
        [disabled]="filterForm.invalid"
        (click)="applyFilter()"></ui-button>
</div>
<ng-content select="[preview]"></ng-content>

import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { User } from './models/account.model';
import { AccountAccessService } from './services/api/account-access.service';

export const userResolver: ResolveFn<User> = () => {
    const accountAccessService = inject(AccountAccessService);

    return accountAccessService.getAccountInfo().pipe(
        switchMap(accountInfo => {
            return accountAccessService.getUser(accountInfo.accountSlug, accountInfo.brandSlug);
        }),
        tap(user => accountAccessService.setUser(user))
    );
};

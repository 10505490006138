<div
    #formField
    class="tw-w-full">
    @if (fieldType === FeedFieldType.Creative) {
        <p class="tw-text-sm tw-pl-5">{{ creativeText }}</p>
    } @else if (fieldType === FeedFieldType.StaticText) {
        <mat-form-field
            appearance="outline"
            class="tw-w-full tw-h-14 tw-text-sm">
            <mat-label>{{ label }}</mat-label>
            <input
                type="text"
                [formControl]="fieldFormControl"
                placeholder="Static Text"
                matInput />
        </mat-form-field>
    } @else {
        <mat-form-field
            class="field-select"
            (click)="isOpen = !isOpen"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin">
            <mat-label>{{ label }}</mat-label>
            <mat-chip-grid
                #chipGrid
                [formControl]="fieldFormControl">
                @for (keyword of fieldFormControl.value; track keyword) {
                    <mat-chip-row
                        (removed)="removeField(keyword)"
                        (click)="onChipClick(keyword, $event)"
                        color="primary"
                        highlighted
                        [value]="keyword"
                        class="chip-row">
                        {{ keyword | splitLast: '.' }}
                        <button
                            matChipRemove
                            aria-label="'remove ' + keyword"
                            class="chip-btn">
                            <mat-icon class="chip-icon">close</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                <input
                    placeholder=""
                    [matChipInputFor]="chipGrid"
                    readonly
                    (matChipInputTokenEnd)="add($event)" />
                <span
                    class="icon-btn"
                    matSuffix
                    ><svg
                        viewBox="0 0 24 24"
                        width="24px"
                        height="24px"
                        focusable="false"
                        aria-hidden="true"
                        fill="#737373">
                        <path
                            d="M7 10l5 5 5-5z"
                            class="ng-tns-c1771602899-9"></path>
                    </svg>
                </span>
            </mat-chip-grid>
        </mat-form-field>

        <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen"
            [cdkConnectedOverlayFlexibleDimensions]="true"
            [cdkConnectedOverlayViewportMargin]="10"
            [cdkConnectedOverlayPositions]="[
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top',
                    offsetX: 0,
                    offsetY: 0
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            ]"
            [cdkConnectedOverlayWidth]="formField.getBoundingClientRect().width + 100"
            (overlayOutsideClick)="openSelect($event)">
            <div class="tw-overscroll-x-none tw-w-full tw-rounded-md ui-scrollbar">
                <ui-panel>
                    <div
                        panel-header
                        class="tw-flex tw-flex-row tw-justify-between tw-w-full tw-items-center">
                        <div class="tw-text-sm tw-font-semibold tw-pl-6">{{ label | titlecase }}</div>
                        <ui-button
                            class="transparent-btn"
                            type="default"
                            svgIcon="close"
                            [border]="false"
                            (click)="openSelect($event)"></ui-button>
                    </div>
                    <div class="tw-overflow-y-auto tw-max-h-96 tw-h-full tw-p-4 tw-overflow-x-hidden">
                        @if (feedSchemaSource$ | async; as feedSchemaSource) {
                            <feed-tree-fields
                                [fieldFormControl]="fieldFormControl"
                                [feedSchemaSource]="feedSchemaSource" />
                        } @else {
                            <div class="tw-flex tw-justify-center">
                                <ui-loader
                                    [inline]="true"
                                    class="tw-p-4"></ui-loader>
                            </div>
                        }
                    </div>
                </ui-panel>
            </div>
        </ng-template>
    }
</div>

<ui-dialog
    #fieldEditorDialog="ui-dialog"
    [config]="{ headerText: 'EDIT ' + label, maxWidth: 500, maxHeight: 500, padding: 0 }">
    <ng-template ui-dialog-template>
        <field-editor
            (cancelEdit)="fieldEditorDialog.close()"
            (valueChanged)="edit($event)"
            [value]="selectedChip">
        </field-editor>
    </ng-template>
</ui-dialog>

<ui-tabs>
    <ui-tab name="Main feed settings">
        <div class="tw-flex tw-flex-row tw-bg-ui-grey-98 tw-gap-8">
            <card-summary></card-summary>
            <div class="tw-flex tw-w-full tw-flex-col">
                @if (feedEditorData$ | async; as feedEditorData) {
                    <div class="tw-mb-[-30px]">
                        <h1 class="tw-font-normal">Fields</h1>
                        <div class="tw-grid tw-grid-cols-[1fr_1fr_0.5fr]">
                            <div class="tw-flex tw-items-center tw-gap-2.5">
                                <ui-svg-icon icon="crown"></ui-svg-icon>
                                <h3>{{ feedEditorData.mainFeed.name }}</h3>
                            </div>
                            <div class="tw-flex tw-flex-row tw-gap-2 tw-text-sm tw-font-bold tw-items-center">
                                <ui-svg-icon icon="visibility-visible" />
                                Preview
                            </div>
                        </div>
                    </div>
                    @if (feedEditorData.previewData) {
                        <preview-carousel [previewData]="feedEditorData.previewData"></preview-carousel>
                    }
                    @if (feedEditorData.operationsSummary.length > 0) {
                        <div class="tw-mt-13">
                            <h1 class="tw-font-normal">Operations</h1>
                            <operations-summary
                                [operationsSummary]="feedEditorData.operationsSummary"></operations-summary>
                        </div>
                    }
                    <div class="tw-mt-13">
                        <h1 class="tw-font-normal">Settings</h1>
                        <div class="tw-grid tw-grid-cols-2 tw-gap-8">
                            <div class="tw-flex tw-flex-col tw-gap-2">
                                <mat-form-field>
                                    <mat-label>Name</mat-label>
                                    <input
                                        matInput
                                        placeholder=""
                                        [value]="'Main Feed 1'" />
                                </mat-form-field>
                                <ui-button
                                    class="tw-mt-3"
                                    svgIcon="edit"
                                    text="Make changes in fields & operations"></ui-button>
                            </div>
                            <div class="tw-px-2.5">
                                <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                                    <mat-form-field>
                                        <mat-label>Update Interval</mat-label>
                                        <mat-select
                                            [value]="feedEditorData.mainFeed.updateInterval"
                                            #intervalSelect>
                                            @if (readableUpdateIntervals$ | async; as intervals) {
                                                @for (interval of intervals; track interval) {
                                                    <mat-option [value]="interval.value">{{
                                                        interval.label
                                                    }}</mat-option>
                                                }
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                    <ui-button
                                        [disabled]="feedEditorData.mainFeed.updateInterval === intervalSelect.value"
                                        class="tw-h-10"
                                        type="primary"
                                        (click)="updateInterval(feedEditorData.mainFeed, intervalSelect.value)"
                                        text="Update interval"></ui-button>
                                </div>
                                <div class="w-full">
                                    <p class="tw-mb-5">
                                        Specify how often the feed should be fetched from the server and updated. Try to
                                        avoid picking an interval with an unnecessary short timespan.
                                    </p>
                                    <ui-checkbox
                                        label="Allow field values to be empty"
                                        [(selected)]="isFieldValueEmpty"></ui-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </ui-tab>
    <ui-tab name="Sub-feeds">
        <h1>Tab 2 content</h1>
    </ui-tab>
</ui-tabs>

<div class="connect-feed">
    <img
        src="./assets/sharon-likes-transparent-pngs.png"
        alt="feed" />
    <h3>Let's start by connecting your feed</h3>
    <div class="tw-flex tw-flex-col tw-gap-5 tw-items-center tw-mb-5 tw-max-w-sm tw-w-full">
        <mat-form-field class="tw-w-full">
            <mat-label>Paste a URL to a .XML, .JSON and .CSV file here...</mat-label>
            <input
                matInput
                placeholder="Feed URL"
                [(ngModel)]="inputValue" />
        </mat-form-field>
        <ui-button
            class="tw-max-w-xs"
            type="default"
            svgIcon="feeds"
            (click)="connectFeed()"
            text="CONNECT"></ui-button>
    </div>
    <span class="flex items-center">
        <hr class="line-left" />
        <span class="tw-text-sm">or</span>
        <hr class="line-right" />
    </span>
    <ui-button
        type="default"
        svgIcon="cloud-l-negative"
        text="BROWSE"></ui-button>
    <p>Google Drive, Microsoft OneDrive, SharePoint etc.</p>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreativesData } from '../../models/creative.model';
@Injectable({
    providedIn: 'root'
})
export class StudioService {
    http = inject(HttpClient);

    getCreativesAndVersions(creativeSetId: string): Observable<CreativesData> {
        return this.http.get<CreativesData>(`${environment.origins.sapi}/creative-sets/${creativeSetId}`).pipe(
            map(data => ({
                creatives: data.creatives,
                sizes: data.sizes,
                versions: data.versions
            }))
        );
    }
}

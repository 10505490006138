import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    mergeMap,
    of,
    shareReplay,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom
} from 'rxjs';
import { FeedFieldType, FeedUpdateInterval, GenericFeedField } from 'src/app/models/feed.model';
import { Template } from 'src/app/models/templates.model';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { PreviewService } from 'src/app/services/api/preview.service';
import { TemplateService } from 'src/app/services/api/template.service';
import { StepperService } from 'src/app/services/ui/stepper.service';
import { GenericFeedFieldFormGroup, TemplateBuilderService } from 'src/app/services/ui/template-builder.service';
import { AddFieldComponent } from 'src/app/shared/add-field/add-field.component';
import { BottomNavigationComponent } from 'src/app/shared/bottom-navigation/bottom-navigation.component';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { FeedTreeFieldsComponent } from 'src/app/shared/field-select/feed-tree-fields/feed-tree-fields.component';
import { FieldSelectComponent } from 'src/app/shared/field-select/field-select.component';
import { LoadingAnimationComponent } from 'src/app/shared/loading-animation/loading-animation.component';
import { PreviewValueComponent } from 'src/app/shared/preview-value/preview-value.component';
import { mapFormGroupToFeedFields } from 'src/app/utils/main-feed-helper';
import { isDefined } from 'src/app/utils/rxjs-helper';
import { v4 as uuidv4 } from 'uuid';
import { FiltersComponent } from '../../../shared/filters/filters.component';
import { PreviewCarouselComponent } from '../../feed-editor/preview-carousel/preview-carousel.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FieldEditorComponent } from './field-editor/field-editor.component';
import { FieldsSuggestionsComponent } from './fields-suggestions/fields-suggestions.component';
import { PreviewTableComponent } from './preview-table/preview-table.component';

@Component({
    selector: 'select-fields',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FieldSelectComponent,
        FeedTreeFieldsComponent,
        MatIconModule,
        UIModule,
        MatTabsModule,
        FieldsSuggestionsComponent,
        PreviewTableComponent,
        FieldEditorComponent,
        PreviewCarouselComponent,
        LoadingAnimationComponent,
        DynamicFormComponent,
        EditableFieldComponent,
        FiltersComponent,
        AddFieldComponent,
        PreviewValueComponent,
        BottomNavigationComponent
    ],
    templateUrl: './select-fields.component.html',
    styleUrls: ['./select-fields.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldsComponent {
    private templateService = inject(TemplateService);
    private templateBuilder = inject(TemplateBuilderService);
    private feedSourceService = inject(FeedSourceService);
    private mainfeedService = inject(MainFeedService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private uiNotificationService = inject(UINotificationService);
    private previewService = inject(PreviewService);
    private stepperService = inject(StepperService);

    private currentPage$ = new BehaviorSubject<number>(1);
    private suggestedFields$ = this.getSuggestedFields().pipe(shareReplay(1));
    mainFeedId$ = this.mainfeedService.mainFeedId$;
    loading$ = new BehaviorSubject<boolean>(false);

    templateFormGroup$ = this.suggestedFields$.pipe(
        distinctUntilChanged((prevFields, currFields) => prevFields.length === currFields.length),
        tap(val => {
            const containsCreativeType = val.some(field => field.$type === FeedFieldType.Creative);
            this.mainFeedFieldsService.setCreativeFieldType(containsCreativeType);
        }),
        withLatestFrom(this.templateService.selectedTemplate$),
        map(([fields, template]) => this.templateBuilder.buildForm(fields, template.type !== 'DisplayTemplate')),
        shareReplay(1)
    );

    formRawValueChanges$ = this.templateFormGroup$.pipe(
        mergeMap(formGroup =>
            formGroup.valueChanges.pipe(
                startWith(mapFormGroupToFeedFields(formGroup.controls.fields)),
                map(_ => mapFormGroupToFeedFields(formGroup.controls.fields))
            )
        ),
        filter(isDefined)
    );

    previewData$ = combineLatest([this.feedSourceService.feedSourceUrl$, this.formRawValueChanges$]).pipe(
        map(([feedSourceUrl, fields]) => {
            //Filter fields to remove those with an empty path
            const filteredFields = fields.filter(field => field.path !== '' && field.$type === FeedFieldType.Path);
            return { feedSourceUrl, filteredFields };
        }),
        switchMap(({ feedSourceUrl, filteredFields }) => {
            // Only proceed if there are any non-empty fields
            if (filteredFields.length > 0) {
                return this.previewService.getPreviewData({
                    source: feedSourceUrl,
                    fields: filteredFields,
                    filters: []
                });
            } else {
                // Return an observable that emits nothing
                return of({ data: [], total: 0 });
            }
        }),
        startWith({ data: [], total: 0 })
    );

    templateData$ = combineLatest([
        this.templateService.selectedTemplate$,
        this.templateFormGroup$,
        this.currentPage$,
        this.previewData$
    ]).pipe(
        map(([template, formGroup, currentPage, previewData]) => ({
            template,
            formGroup,
            currentPage,
            previewData
        }))
    );

    saveBlueprint(templateName: string, templateId: string, formGroup: GenericFeedFieldFormGroup): void {
        this.loading$.next(true);

        const fields = mapFormGroupToFeedFields(formGroup.controls.fields);

        this.feedSourceService.feedSourceUrl$
            .pipe(
                switchMap(feedSourceUrl =>
                    this.mainfeedService.saveMainFeed(feedSourceUrl, fields, templateName, templateId)
                ),
                take(1),
                finalize(() => this.loading$.next(false))
            )
            .subscribe({
                next: data => {
                    this.uiNotificationService.open(`Saved ${data}`, {
                        type: 'success',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                    this.mainFeedFieldsService.setFields(fields);
                    this.stepperService.completeStep();
                },
                error: error =>
                    this.uiNotificationService.open(error.message, {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    })
            });
    }

    updateBlueprint(templateName: string, id: string, formGroup: GenericFeedFieldFormGroup): void {
        this.loading$.next(true);

        const fields = mapFormGroupToFeedFields(formGroup.controls.fields);

        this.feedSourceService.feedSourceUrl$
            .pipe(
                switchMap(feeddSourceUrl =>
                    this.mainfeedService.updateMainFeed({
                        blueprint: { fields },
                        id,
                        name: templateName,
                        sourceUrl: feeddSourceUrl,
                        updateInterval: FeedUpdateInterval.None
                    })
                ),
                take(1),
                finalize(() => this.loading$.next(false))
            )
            .subscribe({
                next: data => {
                    this.uiNotificationService.open(`Updated ${data}`, {
                        type: 'success',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    });
                    this.mainFeedFieldsService.setFields(fields);
                    this.stepperService.completeStep();
                },
                error: error =>
                    this.uiNotificationService.open(error.message, {
                        type: 'error',
                        autoCloseDelay: 3000,
                        placement: 'top'
                    })
            });
    }

    createNewField(newSDAField: string, formGroup: GenericFeedFieldFormGroup): void {
        const newField: GenericFeedField = {
            $type: FeedFieldType.Path,
            name: newSDAField ? newSDAField : 'New field',
            id: uuidv4(),
            path: ''
        };
        formGroup.controls.fields.push(this.templateBuilder.buildFieldGroup(newField));
    }

    updateTemplateLabel(label: string, template: Template) {
        this.templateService.selectTemplate({ ...template, label });
    }

    previewPrevious(currentPage: number): void {
        currentPage = currentPage - 1;
        this.currentPage$.next(currentPage);
    }

    previewNext(currentPage: number): void {
        currentPage = currentPage + 1;
        this.currentPage$.next(currentPage);
    }

    private getSuggestedFields() {
        const feedSourceUrl$ = this.feedSourceService.feedSourceUrl$;
        const selectedTemplate$ = this.templateService.selectedTemplate$;

        return this.mainFeedFieldsService.getSuggestedFields(feedSourceUrl$, selectedTemplate$);
    }
}

import { ArrayDataSource } from '@angular/cdk/collections';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { map } from 'rxjs';
import { FeedFieldType } from 'src/app/models/feed.model';
import { SplitLastPipe } from 'src/app/pipes/split-last.pipe';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { FieldEditorComponent } from 'src/app/views/stepper/select-fields/field-editor/field-editor.component';
import { FeedTreeFieldsComponent } from './feed-tree-fields/feed-tree-fields.component';

@Component({
    selector: 'field-select',
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatIconModule,
        OverlayModule,
        MatButtonModule,
        UIModule,
        FieldEditorComponent,
        SplitLastPipe,
        FeedTreeFieldsComponent,
        MatInputModule
    ],
    templateUrl: './field-select.component.html',
    styleUrls: ['./field-select.component.scss']
})
export class FieldSelectComponent {
    @Input({ required: true }) fieldFormControl: FormControl;
    @Input({ required: true }) label: string;
    @Input() fieldType: FeedFieldType;
    @Input() id: string;
    @ViewChild('fieldEditorDialog') public fieldEditorDialog: UIDialogDirective;
    private feedSourceService = inject(FeedSourceService);
    feedSchemaSource$ = this.feedSourceService.getFeedSourceFields().pipe(map(nodes => new ArrayDataSource(nodes)));
    FeedFieldType = FeedFieldType;
    selectedChip: string;
    isOpen: boolean = false;
    creativeText = 'The creative can be connected in the next step';
    removeField(keyword: string) {
        const index = this.fieldFormControl.value.indexOf(keyword);
        if (index >= 0) {
            const newValue = this.fieldFormControl.value.splice(index, 1);
            this.fieldFormControl.setValue(index === 0 ? [] : newValue);
        }
    }

    onChipClick(keyword: string, event: Event): void {
        event.stopPropagation();
        this.selectedChip = keyword;
        this.fieldEditorDialog.open();
    }

    add(event: MatChipInputEvent): void {
        this.setChipValue(event.value);
        event.chipInput!.clear();
    }

    edit(value: string): void {
        this.setChipValue(value);
    }

    openSelect(event: Event) {
        event.stopPropagation();
        this.isOpen = !this.isOpen;
    }

    setChipValue(value: string): void {
        if (value == '') throw new Error('Empty chip value is not allowed');

        value = value.trim();

        this.fieldFormControl.setValue([value]);
    }
}

import { Overlay, OverlayRef, OverlaySizeConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, fromEvent, takeUntil } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DraggableBottomSheetService<T> {
    overlay = inject(Overlay);
    overlayRef: OverlayRef;
    private _bottomSheetCreated = new BehaviorSubject<boolean>(false);
    bottomSheetCreated$ = this._bottomSheetCreated.asObservable();

    createBottomSheet(componentPortal: ComponentPortal<T>) {
        const postion = this.overlay.position().global().centerHorizontally().bottom('0');
        this.overlayRef = this.overlay.create({
            positionStrategy: postion,
            minHeight: '100px',
            maxHeight: '100%',
            minWidth: '100%',
            maxWidth: '100%',
            height: '10vh'
        });
        this.overlayRef.attach(componentPortal);
        this._bottomSheetCreated.next(true);
    }

    removeBottomSheet() {
        this.overlayRef.detach();
    }

    updateSize(sizeConfig: OverlaySizeConfig) {
        this.overlayRef.updateSize(sizeConfig);
    }

    startDrag() {
        const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove');
        const mouseUp$ = fromEvent<MouseEvent>(document, 'mouseup');
        const initialY = this.overlayRef.hostElement.getBoundingClientRect().top;
        const initialHeight = this.overlayRef.hostElement.clientHeight;
        mouseMove$.pipe(takeUntil(mouseUp$)).subscribe(event => {
            const deltaY = event.clientY - initialY;
            const newHeight = initialHeight - deltaY;
            this.overlayRef.updateSize({ height: `${newHeight}px` });
        });
    }

    hideBottomSheet() {
        this.overlayRef.hostElement.style.visibility = 'hidden';
    }

    showBottomSheet() {
        this.overlayRef.hostElement.style.visibility = 'visible';
    }
}

<ui-tabs
    [attr.data-testid]="'field-editor-tabs'"
    class="ui-tabs">
    <ui-tab
        name="EDIT FIELD"
        headerId="edit-tab">
        <ui-input
            class="ui-input"
            #editInput
            [value]="value"></ui-input>
        <p class="preview-text">Preview</p>
        {{ previewValue }}
    </ui-tab>
    <ui-tab
        name="APPLY FILTERS"
        headerId="filter-tab">
    </ui-tab>
    <div class="btn-wrapper">
        <ui-button
            type="default"
            text="Cancel"
            [attr.data-testid]="'edit-cancel'"
            (click)="cancelEdit.emit()">
        </ui-button>
        <ui-button
            id="interaction-edit-confirm"
            type="primary"
            text="Confirm"
            [attr.data-testid]="'edit-confirm'"
            (click)="confirmEdit(editInput.value)">
        </ui-button>
    </div>
</ui-tabs>

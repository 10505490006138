@if (mainFeeds$ | async; as mainFeeds) {
    <ul class="feed-list">
        @for (mainFeed of mainFeeds; track mainFeed) {
            <li class="feed-item">
                <a
                    [routerLink]="['/feed-editor', mainFeed.id]"
                    class="feed-link"
                    >{{ mainFeed.name }}</a
                >
            </li>
        }
    </ul>
}

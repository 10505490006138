@for (control of formArray.controls; track control; let i = $index) {
    <div
        class="tw-w-full tw-grid tw-auto-rows-auto tw-gap-y-0 tw-py-3"
        [ngClass]="headerTemplateRef ? 'tw-grid-cols-3' : 'tw-grid-cols-2'"
        [class.divider]="i !== (formArray.controls | keyvalue).length - 1">
        <div class="field-label">
            <div class="tw-flex tw-items-center">
                @if (templateType !== 'DisplayTemplate') {
                    @if (!control.controls.data.hasValidator(requiredValidator)) {
                        <ui-svg-icon
                            icon="kebab"
                            class="kebab-menu"
                            [uiDropdownTarget]="optionsDropdown" />
                    }
                    <p>{{ control.controls.name.value }}</p>
                } @else {
                    <ui-svg-icon
                        icon="kebab"
                        class="kebab-menu"
                        [uiDropdownTarget]="optionsDropdown" />
                    <editable-field [formCtrl]="control.controls.name">
                        <p>{{ control.controls.name.value }}</p>
                    </editable-field>
                }
            </div>
            <mat-icon
                class="field-icon"
                [class.active]="control.controls.data.value.length > 0 || control.controls.type.value === 'Creative'"
                >link</mat-icon
            >
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
            <select-type
                class="tw-w-34"
                [fieldFormControlType]="control.controls.type"/>
            <field-select
                class="tw-flex tw-items-center tw-w-full"
                [fieldFormControl]="control.controls.data"
                [label]="control.controls.name.value"
                [id]="control.controls.id.value"
                [fieldType]="control.controls.type.value" />
        </div>
        @if (headerTemplateRef) {
            <div class="tw-flex tw-justify-center tw-items-center">
                <ng-container
                    [ngTemplateOutlet]="headerTemplateRef"
                    [ngTemplateOutletContext]="{ $implicit: control.controls.name.value }">
                </ng-container>
            </div>
        }
    </div>
    <ui-dropdown
        theme="default"
        [header]=""
        #optionsDropdown>
        <ui-dropdown-item
            *ngFor="let option of kebabMenuOptions"
            (itemClicked)="onKebabMenuOptionClicked(option, i)">
            {{ option }}
        </ui-dropdown-item>
    </ui-dropdown>
}

import { Injectable } from '@angular/core';
import { FormArray, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FilterGroups, FilterGroupForm, FilterGroup, Filter, FilterForm } from '../../models/filters.model';
@Injectable({
    providedIn: 'root'
})
export class FilterBuilderService {
    constructor(private fb: NonNullableFormBuilder) {}

    buildForm(groups: FilterGroups): FormGroup<{ arrayGroups: FormArray<FormGroup<FilterGroupForm>> }> {
        return this.fb.group({
            arrayGroups: this.buildArrayGroups(groups)
        });
    }

    private buildArrayGroups(groups: FilterGroups): FormArray {
        return this.fb.array(groups.map(group => this.buildGroup(group)));
    }

    buildGroup(group: FilterGroup): FormGroup<FilterGroupForm> {
        const arrayGroupValue = '[]'; // set default value to root

        const formGroup = this.fb.group<FilterGroupForm>({
            arrayGroup: this.fb.control(arrayGroupValue, {
                validators: [Validators.required]
            }),
            label: this.fb.control(group.label || '', { validators: [Validators.required] }),
            filterType: this.fb.control(group.filterType, { validators: [Validators.required] }),
            filters: this.buildFilters(group.filters)
        });

        return formGroup;
    }

    private buildFilters(filters: Filter[]): FormArray {
        return this.fb.array(filters.map(filter => this.buildFilter(filter)));
    }

    buildFilter(filter: Filter): FormGroup<FilterForm> {
        return this.fb.group({
            field: this.fb.control(filter.field, Validators.required),
            operator: this.fb.control(filter.operator, Validators.required),
            arguments: this.fb.array(
                filter.arguments.map(argument =>
                    this.fb.control({ value: argument, disabled: argument.toString().length <= 0 }, Validators.required)
                )
            )
        });
    }
}

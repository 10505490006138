<h2 class="tw-text-center">Now match your fields with the template fields</h2>
@if (templateData$ | async; as templateData) {
    <div class="form-wrapper">
        <div class="tw-w-full tw-grid tw-grid-cols-3 tw-auto-rows-auto tw-gap-y-0">
            <div>
                <editable-field
                    [value]="templateData.template.label"
                    (valueChanged)="updateTemplateLabel($event, templateData.template)">
                    <h3>
                        {{ templateData.template.label }}
                    </h3>
                </editable-field>
                <h3 class="tw-text-ui-primary">Template fields</h3>
            </div>

            <div class="tw-flex tw-flex-col tw-justify-end">
                <div class="tw-h-14 tw-flex tw-items-center">File type</div>
                <h3 class="tw-text-ui-primary">Imported fields from your source</h3>
            </div>

            <div class="tw-flex tw-flex-col tw-justify-end tw-pl-2">
                <div class="tw-h-14 tw-flex tw-items-center">Preview</div>
                <div class="tw-flex tw-justify-between">
                    <h3 class="tw-text-ui-primary">Values from your source</h3>
                    @if (templateData.previewData.total > 1) {
                        <div class="tw-flex tw-flex-row tw-gap-1 tw-items-center">
                            <ui-button
                                (click)="previewPrevious(templateData.currentPage)"
                                ui-theme="minimal"
                                [svgIcon]="'arrow-left'"
                                [disabled]="templateData.currentPage === 1"></ui-button>
                            <p>{{ templateData.currentPage }} / {{ templateData.previewData.total }}</p>
                            <ui-button
                                (click)="previewNext(templateData.currentPage)"
                                ui-theme="minimal"
                                [svgIcon]="'arrow-right'"
                                [disabled]="templateData.currentPage >= templateData.previewData.total"></ui-button>
                        </div>
                    }
                </div>
            </div>
        </div>

        <form [formGroup]="templateData.formGroup">
            <ng-container formArrayName="fields">
                <div class="tw-grid tw-grid-cols-3">
                    <dynamic-form
                        [ngClass]="templateData.previewData.total === 0 ? 'tw-col-span-2' : 'tw-col-span-3'"
                        [formArray]="templateData.formGroup.controls.fields"
                        [templateType]="templateData.template.type">
                        @if (templateData.previewData.total > 0) {
                            <ng-template
                                let-fieldname
                                #header>
                                <preview-value
                                    [previewValue]="
                                        templateData.previewData.data[templateData.currentPage - 1].data[fieldname] + ''
                                    " />
                            </ng-template>
                        }
                    </dynamic-form>
                    @if (templateData.previewData.total === 0) {
                        <div class="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-3">
                            Data preview will become available as soon as you start mapping your fields.
                            <img
                                src="
                        assets/svgs/preview_placeholder.svg" />
                        </div>
                    }
                </div>
            </ng-container>
        </form>
    </div>

    <div class="tw-flex tw-justify-between tw-items-center tw-mt-5">
        <add-field
            (addNewField)="createNewField($event, templateData.formGroup)"
            [templateType]="templateData.template.type"></add-field>
        <bottom-navigation>
            <div class="tw-flex tw-gap-6 tw-pr-5">
                @if (templateData.formGroup.invalid) {
                    <p class="tw-text-ui-warning">
                        <ui-svg-icon [icon]="'warning-l-negative'" class="info-fields"></ui-svg-icon>Connect all fields before proceeding
                    </p>
                }
                @if (mainFeedId$ | async; as mainFeedId) {
                    <ui-button
                        type="primary"
                        [loading]="(loading$ | async)!"
                        text="UPDATE FEED"
                        (click)="
                            updateBlueprint(templateData.template.label, mainFeedId, templateData.formGroup)
                        "></ui-button>
                } @else {
                    <ui-button
                        type="primary"
                        [loading]="(loading$ | async)!"
                        (click)="
                            saveBlueprint(templateData.template.label, templateData.template.id, templateData.formGroup)
                        "
                        [disabled]="templateData.formGroup.invalid"
                        text="NEXT" />
                }
            </div>
        </bottom-navigation>
    </div>
} @else {
    <div class="loader">
        <loading-animation class="pacman"></loading-animation>
    </div>
}

<ui-dialog
    #previewDialog="ui-dialog"
    [config]="{ headerText: 'TABLE VIEW', maxWidth: 898, maxHeight: 531 }">
    <ng-template ui-dialog-template>
        <preview-table></preview-table>
    </ng-template>
</ui-dialog>

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'loading-animation',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './loading-animation.component.html',
    styleUrls: ['./loading-animation.component.scss']
})
export class LoadingAnimationComponent {}

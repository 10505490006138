import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, filter, shareReplay } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { AccountInfo, User } from '../../models/account.model';

@Injectable({
    providedIn: 'root'
})
export class AccountAccessService {
    private http = inject(HttpClient);

    private _user = new BehaviorSubject<User | null>(null);
    user$ = this._user.asObservable().pipe(filter(Boolean));

    getUser(accountSlug: string, brandSlug: string): Observable<User> {
        return this.http
            .get<User>(`${env.origins.accountAccessService}/api/${accountSlug}/${brandSlug}/users/current`)
            .pipe(shareReplay(1));
    }

    getAccountInfo(): Observable<AccountInfo> {
        return this.http.get<AccountInfo>(`${env.origins.accountAccessService}/api/user/defaults`);
    }

    setUser(user: User): void {
        this._user.next(user);
    }
}

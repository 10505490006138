@if (creativeListData$ | async; as creativeListData) {
    <ui-panel class="tw-p-10">
        <ng-container panel-header>
            <ui-breadcrumbs class="tw-pl-5">
                <ui-breadcrumb
                    *ngFor="let breadcrumb of creativeListData.breadCrumbs; let i = index"
                    (click)="breadcrumbSelected(breadcrumb.folderId)"
                    >{{ breadcrumb.folderName }}</ui-breadcrumb
                >
            </ui-breadcrumbs>
        </ng-container>
        <ui-input
            [placeholder]="'Search'"
            [attr.data-testid]="'search-input'"
            class="tw-px-5 tw-pt-5"
            icon="search"
            [value]="creativeListData.searchInputValue"
            (valueChange)="searchedText($event)">
        </ui-input>
        <div
            #dialogTable
            infiniteScroll
            (scrolledToBottom)="loadMore(creativeListData.pagination, creativeListData.totalItems)"
            class="tw-scroll-auto tw-h-[280px] ui-scrollbar tw-overflow-auto tw-mx-5 tw-my-3">
            <ui-table
                [loading]="!!(loading$ | async)"
                (rowClicked)="folderClicked($event)"
                [config]="{ tableHeaderSticky: true }"
                class="table"
                [columnNames]="creativeListData.columnNames"
                [dataSource]="creativeListData.dataSource"
                matSort
                matSortDisableClear>
                <ng-container cdkColumnDef="name">
                    <th
                        cdk-header-cell
                        *cdkHeaderCellDef
                        mat-sort-header="name"
                        [ngStyle]="{ width: '80%' }"
                        class="cell-header text">
                        Creative sets
                    </th>
                    <td
                        cdk-cell
                        *cdkCellDef="let row; let i = index"
                        class="cell text">
                        <div
                            [uiTooltip]="row.name"
                            uiTooltipMaxWidth="500px"
                            [attr.data-testid]="'list-name-' + i">
                            <ui-skeleton-cell
                                class="skeleton-cell"
                                *ngIf="loading$ | async; else content">
                            </ui-skeleton-cell>
                            <ng-template #content>
                                <ui-svg-icon
                                    *ngIf="row.type === 'Folder'"
                                    icon="folder"
                                    fill="var(--ui-color-primary)"
                                    class="folder"></ui-svg-icon>

                                {{ row.name }}
                            </ng-template>
                        </div>
                    </td>
                </ng-container>
            </ui-table>
        </div>
    </ui-panel>
} @else {
    <div class="tw-w-full tw-flex tw-justify-center tw-items-center tw-h-[400px]">
        <ui-inline-loader></ui-inline-loader>
    </div>
}

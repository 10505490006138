import { Component, DestroyRef, ElementRef, inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { Creative } from 'src/app/models/creative.model';
import { CreativePreviewService } from 'src/app/services/api/creative-preview.service';
@Component({
    selector: 'creative-preview',
    standalone: true,
    imports: [UIModule],
    templateUrl: './creative-preview.component.html',
    styleUrl: './creative-preview.component.scss'
})
export class CreativePreviewComponent {
    @Input() selectedCreative: Creative;
    @ViewChild('container') container: ElementRef;
    @ViewChild('previewContainer') private previewContainer: ElementRef;
    newCreativeWidth: number;

    creativePreviewService = inject(CreativePreviewService);
    private destroyRef = inject(DestroyRef);

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedCreative']) {
            this.loadCreativePreview();
        }
    }

    private loadCreativePreview(): void {
        if (this.selectedCreative) {
            this.creativePreviewService
                .getCreativePreviewUrl(this.selectedCreative.creativeSetId, this.selectedCreative.id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(url => {
                    const constructedUrl = this.constructUrlFrom(url.previewUrl);
                    this.createPreviewScriptElement(constructedUrl);

                    const creativeWidth = this.selectedCreative.size.width;
                    const creativeHeight = this.selectedCreative.size.height;
                    this.newCreativeWidth = this.adjustPreviewWidthToContainer(creativeWidth, creativeHeight);
                });
        }
    }

    private adjustPreviewWidthToContainer(creativeWidth: number, creativeHeight: number): number {
        const containerWidth = this.previewContainer.nativeElement.offsetWidth;
        const containerHeight = this.previewContainer.nativeElement.offsetHeight;
        if (creativeHeight > containerHeight) {
            const scalingRatio = containerHeight / creativeHeight;
            const adjustedImageWidth = creativeWidth * scalingRatio;
            const adjustedWidthPercentage = Math.ceil((adjustedImageWidth / containerWidth) * 100);

            return adjustedWidthPercentage > 100 ? 100 : adjustedWidthPercentage;
        }

        return 100;
    }

    private constructUrlFrom(previewURL: string): string {
        return `${previewURL?.replace('preview', 'ad.js')}&responsive=on`;
    }

    private createPreviewScriptElement(source: string): void {
        const container = this.container.nativeElement;
        this.removeAllChildren(container);
        this.appendScriptToContainer(container, source);
    }

    private removeAllChildren(container: HTMLElement): void {
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
    }

    private appendScriptToContainer(container: HTMLElement, source: string): void {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.src = source;
        container.appendChild(scriptTag);
    }
}

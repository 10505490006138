import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { UIModule } from '@bannerflow/ui';
import { FieldSelectComponent } from './shared/field-select/field-select.component';
import { StepperComponent } from './views/stepper/stepper.component';
import { SandboxPrSlotsComponent } from 'src/app/shared/sandbox-pr-slots/sandbox-pr-slots.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        FieldSelectComponent,
        StepperComponent,
        UIModule,
        RouterModule,
        SandboxPrSlotsComponent
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    environment = environment;
    constructor() {
        (window as any).origins = environment.origins;
    }
}

import { Injectable, inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { FeedFieldType, GenericFeedField } from 'src/app/models/feed.model';

export interface GenericFeedFieldForm {
    type: FormControl<FeedFieldType>;
    name: FormControl<string>;
    id: FormControl<string>;
    data: FormControl<string>; // Can either be a Path or a Static Text
}

export type GenericFeedFieldFormGroup = FormGroup<{ fields: FormArray<FormGroup<GenericFeedFieldForm>> }>;

@Injectable({
    providedIn: 'root'
})
export class TemplateBuilderService {
    fb = inject(NonNullableFormBuilder);

    buildForm(fields: GenericFeedField[], pathRequired = false): GenericFeedFieldFormGroup {
        const group = this.fb.group({
            fields: this.buildFieldsArray(fields, pathRequired)
        });

        return group;
    }

    private buildFieldsArray(
        fields: GenericFeedField[],
        pathRequired: boolean
    ): FormArray<FormGroup<GenericFeedFieldForm>> {
        return this.fb.array(fields.map(field => this.buildFieldGroup(field, pathRequired)));
    }

    buildFieldGroup(field: GenericFeedField, pathRequired = false): FormGroup<GenericFeedFieldForm> {
        const baseGroup = this.fb.group<GenericFeedFieldForm>({
            type: this.fb.control(field.$type),
            name: this.fb.control(field.name),
            id: this.fb.control(field.id),
            data: this.fb.control(field.path !== undefined && field.path !== '' ? field.path : '', {
                validators: pathRequired && field.$type !== 'Creative' ? Validators.required : null
            })
        });
        return baseGroup;
    }
}

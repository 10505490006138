import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UINotificationService } from '@bannerflow/ui';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreativeSetTableData, CreativeSetListItem } from '../../models/creative-set-folders.model';

@Injectable({
    providedIn: 'root'
})
export class ListService {
    httpClient = inject(HttpClient);
    notificationService = inject(UINotificationService);

    getCreativesetsandFolders(
        page: number,
        pageSize: number,
        folderId: string,
        brandId: string
    ): Observable<CreativeSetTableData> {
        brandId = environment.stage === 'sandbox' ? '632dbb6d847b22c78b9efb06' : '664dadc68127265655ff19bb';
        const url = `${environment.origins.listService}/api/${brandId}/creativesets/GetCreativeSetAndFolders`;
        const requestBody = Object.assign(
            { paging: { pageNumber: page, pageSize: pageSize } },
            folderId === 'root' ? null : { folderId },
            { sort: { fieldName: 'name', order: 'asc' } }
        );
        return this.httpClient.post<CreativeSetTableData>(url, requestBody).pipe(
            map(val => this.createCreativesetTableData(val)),
            catchError(errorResponse => {
                this.errorNotification(errorResponse);
                return EMPTY;
            })
        );
    }

    getFilteredCreativesetAndFolders(
        nameSearch: string,
        page: number,
        pageSize: number,
        brandId: string
    ): Observable<CreativeSetTableData> {
        brandId = environment.stage === 'sandbox' ? '632dbb6d847b22c78b9efb06' : '664dadc68127265655ff19bb';
        const url = `${environment.origins.listService}/api/${brandId}/creativesets/FilterCreativeSetsAndFolders`;
        return this.httpClient
            .post<CreativeSetTableData>(url, {
                paging: {
                    pageNumber: page,
                    pageSize: pageSize
                },
                filters: { name: nameSearch },
                sort: { fieldName: 'name', order: 'asc' }
            })
            .pipe(
                catchError(error => {
                    this.errorNotification(error);
                    return EMPTY;
                }),
                map(val => this.createCreativesetTableData(val))
            );
    }

    private createCreativeSetListItem(item: CreativeSetListItem): CreativeSetListItem {
        return {
            ...item,
            modifiedAt: new Date(item.modifiedAt).toDateString(),
            createdAt: new Date(item.createdAt).toDateString()
        };
    }

    private createCreativesetTableData(val: CreativeSetTableData): CreativeSetTableData {
        if (val.items) {
            return {
                totalItemsCount: val.totalItemsCount,
                items: val.items.map(
                    (item: CreativeSetListItem): CreativeSetListItem => this.createCreativeSetListItem(item)
                ),
                path: val.path
            };
        } else {
            return { totalItemsCount: 0, items: [], path: [] };
        }
    }

    private errorNotification(errorResponse: HttpErrorResponse): void {
        this.notificationService.open('Something went wrong', {
            type: 'error',
            placement: 'top',
            autoCloseDelay: 2000
        });
        throw errorResponse;
    }
}

@if (previewValue | isImage) {
    <div class="tw-w-96 tw-h-96 tw-relative">
        <img
            alt="preview-image"
            class="tw-object-contain"
            [ngSrc]="previewValue"
            fill />
    </div>
} @else {
    <div>
        {{ previewValue === 'undefined' ? '' : previewValue }}
    </div>
}

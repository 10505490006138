import { FormArray, FormGroup } from '@angular/forms';
import { FeedField, FeedFieldType, FeedUpdateInterval, GenericFeedField } from '../models/feed.model';
import { GenericFeedFieldForm } from '../services/ui/template-builder.service';

export function setFieldIds(fields: GenericFeedField[]): GenericFeedField[] {
    fields.forEach(field => {
        if (field.name && field.id == null) {
            field.id = field.name.replace(/\s/g, '');
        }
    });

    return fields;
}

export function findFieldById(fields: GenericFeedField[], id: string): GenericFeedField {
    for (let i = 0; i < fields.length; i++) {
        const currentField = fields[i];
        if (currentField.id === id) {
            return currentField;
        }
    }

    throw new Error(`Field with id ${id} not found`);
}

export function mapFormGroupToFeedFields(formGroup: FormArray<FormGroup<GenericFeedFieldForm>>): FeedField[] {
    return formGroup.controls.map((fieldGroup: FormGroup<GenericFeedFieldForm>) => {
        const type = fieldGroup.controls.type.value;
        const name = fieldGroup.controls.name.value;
        const id = fieldGroup.controls.id.value;
        const data = Array.isArray(fieldGroup.controls.data.value)
            ? fieldGroup.controls.data.value.join('.')
            : fieldGroup.controls.data.value;

        if (type === FeedFieldType.StaticText) {
            return {
                $type: type,
                name: name,
                text: data,
                id: id
            };
        } else if (type === FeedFieldType.Creative) {
            return {
                $type: type,
                name: name,
                creativeSetId: '',
                creativeId: '',
                id: id
            };
        } else {
            return {
                $type: FeedFieldType.Path,
                name: name,
                path: data,
                children: [],
                id: id
            };
        }
    });
}

export function readableUpdateIntervals(): { value: FeedUpdateInterval; label: string }[] {
    return [
        { value: FeedUpdateInterval.None, label: 'Manual' },
        { value: FeedUpdateInterval.TwentyMinute, label: 'Every 20 minutes' },
        { value: FeedUpdateInterval.ThirtyMinutes, label: 'Every 30 minutes' },
        { value: FeedUpdateInterval.OneHour, label: 'Every hour' },
        { value: FeedUpdateInterval.ThreeHours, label: 'Every 3 hours' },
        { value: FeedUpdateInterval.SixHours, label: 'Every 6 hours' },
        { value: FeedUpdateInterval.TwelveHours, label: 'Every 12 hours' },
        { value: FeedUpdateInterval.EveryDay, label: 'Every day' },
        { value: FeedUpdateInterval.EveryWeek, label: 'Every week' }
    ];
}



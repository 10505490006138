import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIModule } from '@bannerflow/ui';

@Component({
    selector: 'field-editor',
    standalone: true,
    imports: [CommonModule, UIModule],
    templateUrl: './field-editor.component.html',
    styleUrl: './field-editor.component.scss'
})
export class FieldEditorComponent {
    @Input() value: string;
    @Output() cancelEdit: EventEmitter<void> = new EventEmitter<void>();
    @Output() valueChanged = new EventEmitter<string>();

    previewValue: string = 'dummyPreview';

    confirmEdit(editInput: string | undefined) {
        if (editInput !== this.value && editInput !== undefined) {
            this.valueChanged.emit(editInput);
        }
        this.cancelEdit.emit();
    }
}

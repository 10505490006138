import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CreativePreviewService {
    http = inject(HttpClient);

    getCreativePreviewUrl(creativeSetId: string, creativeId: string): Observable<any> {
        return this.http.get<string>(
            `${environment.origins.acg}/preview-url?creativeset=${creativeSetId}&creative=${creativeId}`
        );
    }
}

import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { UIColumnDef, UIModule, UITableDataSource } from '@bannerflow/ui';
import { Observable, catchError, combineLatest, filter, map, of, switchMap } from 'rxjs';
import { FeedSourceService } from 'src/app/services/api/feed-source.service';
import { MainFeedFieldsService } from 'src/app/services/api/main-feed-fields.service';
import { MainFeedService } from 'src/app/services/api/main-feed.service';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { PreviewService } from 'src/app/services/api/preview.service';

interface TablePreviewData {
    columnNames: UIColumnDef[];
    dataSource: UITableDataSource<any>;
}

@Component({
    selector: 'preview-table',
    standalone: true,
    imports: [CommonModule, UIModule],
    templateUrl: './preview-table.component.html',
    styleUrls: ['./preview-table.component.scss']
})
export class PreviewTableComponent {
    private previewService = inject(PreviewService);
    private mainFilterService = inject(MainFilterService);
    private mainFeedService = inject(MainFeedService);
    private mainFeedFieldsService = inject(MainFeedFieldsService);
    private feedSourceService = inject(FeedSourceService);

    previewData$: Observable<TablePreviewData>;

    constructor() {
        this.previewData$ = combineLatest([
            this.mainFilterService.groupFilters$.pipe(
                map(groupFilters => {
                    // remove property label from every group since
                    // its not needed for the request
                    return groupFilters.map(groupFilter => ({
                        arrayGroup: groupFilter.arrayGroup,
                        filterType: groupFilter.filterType,
                        filters: groupFilter.filters
                    }));
                })
            ),
            this.mainFeedService.mainFeedId$,
            this.mainFeedFieldsService.fields$,
            this.feedSourceService.feedSourceUrl$
        ]).pipe(
            switchMap(([filters, id, fields, sourceUrl]) => {
                return this.previewService
                    .getPreviewData({
                        id,
                        filters,
                        fields,
                        source: sourceUrl
                    })
                    .pipe(
                        map(previewData => ({ previewData, fields })),
                        // Handle errors and return an empty array in case of an error
                        catchError(() => {
                            return of({ previewData: { data: [], total: 0 }, fields }); // return empty data if there's an error
                        })
                    );
            }),
            map(({ previewData, fields }) => {
                const columnNames: UIColumnDef[] = fields.map(field => ({
                    name: field.name,
                    columnDef: field.name,
                    width: '100%'
                }));

                const dataSource = new UITableDataSource();
                dataSource.setData(previewData.data.length > 0 ? previewData.data.map(item => item.data) : []);

                return {
                    columnNames,
                    dataSource
                };
            })
        );
    }
}

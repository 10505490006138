@if (isFinished) {
    <div
        [@fadeInOut]
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-20">
        @if (isSocial$ | async) {
            @if (generatedXML$ | async; as generatedXML) {
                <h2>Copy your generated Meta XML</h2>
                <mat-form-field class="tw-w-full tw-max-w-xs">
                    <mat-label>Generated xml</mat-label>
                    <input
                        matInput
                        readonly
                        [value]="generatedXML" />
                    <button
                        mat-icon-button
                        matSuffix
                        [cdkCopyToClipboard]="generatedXML">
                        <ui-svg-icon icon="copy" />
                    </button>
                </mat-form-field>
            }
        } @else {
            <h2>Feed has been created</h2>
        }

        <ui-button text="Go list of feeds"></ui-button>
    </div>
} @else {
    <ui-tabs [@fadeInOut]>
        <ui-tab
            name="Conditions"
            (selectedChange)="conditionsTabSelected($event)">
            @if (filterGroups$ | async; as filterGroups) {
                <filters [filterGroups]="filterGroups" />
            }
        </ui-tab>
        <ui-tab name="Expression">
            <h1>Tab 2 content</h1>
        </ui-tab>
        <bottom-navigation>
            <div class="tw-flex tw-flex-grow tw-pl-5">
                <ui-button
                    type="default"
                    text="BACK TO FIELD SELECTION"
                    (click)="previousStep()" />
            </div>
            <div class="tw-flex tw-gap-3">
                <p class="tw-text-ui-primary">
                    <ui-svg-icon
                        icon="info-l"
                        class="info-customize" />This step can be skipped and completed later
                </p>
                <ui-button
                    type="primary"
                    text="SAVE MY FEED"
                    [loading]="isLoading"
                    (click)="save()" />
            </div>
        </bottom-navigation>
    </ui-tabs>
}

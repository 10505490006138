import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { BehaviorSubject, combineLatest, map, shareReplay, Subject, switchMap, take } from 'rxjs';
import { SizeThumbnailDirective } from 'src/app/directives/size-thumbnail.directive';
import { Creative, CreativeItem, Size } from 'src/app/models/creative.model';
import { AccountAccessService } from 'src/app/services/api/account-access.service';
import { BrandService } from 'src/app/services/api/brand.service';
import { StudioService } from 'src/app/services/api/studio.service';
import { CreativeListComponent } from '../creative-list/creative-list.component';
import { CreativePreviewComponent } from '../creative-preview/creative-preview.component';
@Component({
    selector: 'creative-selector',
    standalone: true,
    imports: [CommonModule, UIModule, SizeThumbnailDirective, CreativeListComponent, CreativePreviewComponent],
    templateUrl: './creative-selector.component.html',
    styleUrl: './creative-selector.component.scss'
})
export class CreativeSelectorComponent {
    @Output() selectedCreative = new EventEmitter<Creative | null>();
    @Output() closeDialog = new EventEmitter<void>();
    @ViewChild('selectorPreviewContainer') private selectorPreviewContainer: ElementRef;

    studioService = inject(StudioService);
    brandService = inject(BrandService);
    accountAccessService = inject(AccountAccessService);

    private _versionSizeSelection$ = new BehaviorSubject<{ versionId: string; size: Size }>({
        versionId: '',
        size: { id: '', width: 0, height: 0 }
    });
    private _creativeSetId$ = new Subject<string>();
    newCreativePreviewWidth: number;

    creativeSelectorData$ = combineLatest([
        this._creativeSetId$.pipe(
            switchMap(creativeSetId =>
                this.studioService.getCreativesAndVersions(creativeSetId).pipe(
                    map(data => ({
                        ...data,
                        creativeSetId: creativeSetId
                    }))
                )
            )
        ),
        this._versionSizeSelection$,
        this.accountAccessService.user$.pipe(switchMap(user => this.brandService.getBrandById(user.brand.id)))
    ]).pipe(
        map(data => ({
            creativeSetId: data[0].creativeSetId,
            selection: data[1],
            creatives: data[0].creatives,
            versions: data[0].versions.map(version => ({
                ...version,
                cultureCode: data[2].localizations.find(localization => localization.id === version.localization.id)
                    ?.cultureCode
            })),
            sizes: data[0].sizes
        })),
        shareReplay(1)
    );

    selectedCreative$ = this.creativeSelectorData$.pipe(
        map(data => {
            const selectedCreative = data.creatives.find(
                creative =>
                    creative.version.id === data.selection.versionId && creative.size.id === data.selection.size.id
            );

            if (selectedCreative) {
                const { width, height } = data.selection.size;

                return {
                    ...selectedCreative,
                    size: {
                        ...selectedCreative.size,
                        width,
                        height
                    },
                    creativeSetId: data.creativeSetId
                };
            }

            return null;
        })
    );

    setSelectedCreativeSet(creativeSet: CreativeItem): void {
        this._creativeSetId$.next(creativeSet.id);
    }

    selectVersion(newVersionId: string) {
        this._versionSizeSelection$.next({ versionId: newVersionId, size: { id: '', width: 0, height: 0 } });
    }

    selectSize(selection: { versionId: string; size: Size }, newSizeId: string, width: number, height: number): void {
        this._versionSizeSelection$.next({ versionId: selection.versionId, size: { id: newSizeId, width, height } });
    }

    selectCreative(): void {
        this.selectedCreative$.pipe(take(1)).subscribe({
            next: creative => {
                this.selectedCreative.emit(creative);
                this.closeDialog.emit();
            },
            error: error => console.error('Error selecting creative', error)
        });
    }
}

import { CommonModule } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormGroup, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { UIModule } from '@bannerflow/ui';
import { Icon } from '@bannerflow/ui/components/icon/svg-icon/icons';
import { FilterGroupForm, FilterGroups } from 'src/app/models/filters.model';
import { EditableFieldComponent } from 'src/app/shared/editable-field/editable-field.component';
import { FilterSummaryComponent } from 'src/app/shared/filter-summary/filter-summary.component';
import { FieldSelectComponent } from '../field-select/field-select.component';
import { MainFilterService } from 'src/app/services/api/main-filter.service';
import { OperatorsService } from 'src/app/services/api/operators.service';
import { FilterBuilderService } from 'src/app/services/ui/filter-builder.service';
@Component({
    selector: 'filters',
    standalone: true,
    imports: [
        CommonModule,
        UIModule,
        EditableFieldComponent,
        ReactiveFormsModule,
        MatSelectModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        FilterSummaryComponent,
        FieldSelectComponent
    ],
    templateUrl: './filters.component.html',
    styleUrl: './filters.component.scss'
})
export class FiltersComponent implements OnInit {
    @Input({ required: true }) filterGroups: FilterGroups;

    private operatorsService = inject(OperatorsService);
    private filterBuilder = inject(FilterBuilderService);
    private destroyRef = inject(DestroyRef);
    private mainFilterService = inject(MainFilterService);
    private fb = inject(NonNullableFormBuilder);

    operators$ = this.operatorsService.getAllOperators();
    filterForm: FormGroup<{ arrayGroups: FormArray<FormGroup<FilterGroupForm>> }>;
    cardOpenStatus: boolean[];
    actionOptions: { label: string; icon: Icon }[] = [
        { label: 'Filter', icon: 'filter-2' },
        { label: 'Sort', icon: 'filter-2' },
        { label: 'Priority', icon: 'filter-2' }
    ];

    ngOnInit(): void {
        this.filterForm = this.filterBuilder.buildForm(this.filterGroups);

        this.cardOpenStatus = new Array(this.filterForm.controls.arrayGroups.length).fill(false);

        this.filterForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
            this.filterForm.controls.arrayGroups.controls.forEach(filterGroup => {
                if (filterGroup.controls.arrayGroup.value && filterGroup.controls.filters.value.length === 0) {
                    filterGroup.controls.filters.push(
                        this.filterBuilder.buildFilter({ field: '', operator: '', arguments: [''] }),
                        { emitEvent: false }
                    );
                }
                if (!filterGroup.controls.arrayGroup.value) {
                    filterGroup.controls.filters.clear({ emitEvent: false });
                }
                filterGroup.controls.filters.controls.forEach(filter => {
                    const field = filter.controls.field;
                    const formArguments = filter.controls.arguments;

                    if (field.value.length > 0) {
                        formArguments.controls.forEach(control => control.enable({ emitEvent: false }));
                    } else {
                        formArguments.controls.forEach(control => {
                            control.disable({ emitEvent: false });
                            control.setValue('', { emitEvent: false });
                        });
                    }
                });
            });
        });
    }

    addGroupFilter(): void {
        const newFilterGroup = this.filterBuilder.buildGroup({
            label: this.filterForm.controls.arrayGroups.length + 1 + ' - ' + 'My new Filter',
            arrayGroup: '[]',
            filters: [{ field: '', operator: '', arguments: [''] }],
            filterType: 'Include'
        });

        this.filterForm.controls.arrayGroups.push(newFilterGroup);
        for (let i = 0; i < this.cardOpenStatus.length; i++) {
            this.cardOpenStatus[i] = false;
        }
        this.cardOpenStatus.push(true);
    }

    removeFilterGroup(i: number) {
        this.filterForm.controls.arrayGroups.removeAt(i);
        this.cardOpenStatus.splice(i, 1);
    }

    addFilter(filterGroup: FormGroup<FilterGroupForm>, filterIndex: number) {
        const newFilter = this.filterBuilder.buildFilter({
            field: filterGroup.controls.filters.at(filterIndex).controls.field.value,
            operator: filterGroup.controls.filters.at(filterIndex).controls.operator.value,
            arguments: ['']
        });

        filterGroup.controls.filters.push(newFilter);
    }

    removefilter(filterGroup: FormGroup<FilterGroupForm>, filterIndex: number) {
        filterGroup.controls.filters.removeAt(filterIndex);
    }

    toggleCard(index: number) {
        this.cardOpenStatus[index] = !this.cardOpenStatus[index];
    }

    applyFilter() {
        const filterGroups = this.filterForm.controls.arrayGroups.getRawValue();

        const transformedFilterGroups = filterGroups.map(({ filters, ...group }) => ({
            ...group,
            filters: filters.map(filter => ({
                ...filter,
                field: Array.isArray(filter.field) ? filter.field.toString() : filter.field
            }))
        }));

        this.mainFilterService.setFilters(transformedFilterGroups);
    }
}

import { FilterGroups } from './filters.model';
export interface FeedNode {
    name: string;
    children?: FeedNode[];
    isArray: boolean;
    path: string;
    values: string[];
}

export enum FeedFieldType {
    Path = 'Path',
    StaticText = 'StaticText',
    Creative = 'Creative'
}

export type FeedField = GenericPathFeedField | GenericStaticTextFeedField | GenericCreativeFeedField;
export interface GenericFeedField {
    $type: FeedFieldType;
    name: string;
    id: string;
    path?: string;
}
export interface GenericPathFeedField extends GenericFeedField {
    children?: GenericPathFeedField[];
}
export interface GenericStaticTextFeedField extends GenericFeedField {
    text: string;
}
export interface GenericCreativeFeedField extends GenericFeedField {
    creativeSetId: string;
    creativeId: string;
}
export interface FeedValueObject {
    label: string;
    value: string;
    targetUrl: null | string;
}

export interface FeedItem {
    data: {
        [key: string]: FeedValueObject;
    };
}

export interface PreviewData {
    data: FeedItem[];
    total: number;
}
export interface MainFeedName {
    id: string;
    name: string;
}

export interface MainFeed {
    id: string;
    name: string;
    sourceUrl: string;
    blueprint: { fields: GenericFeedField[] };
    filters?: FilterGroups;
    updateInterval?: FeedUpdateInterval;
}

export interface Operator {
    label: string;
    value: DomainFunction;
}

export enum DomainFunction {
    Equal = '==',
    Greater = '>',
    Less = '<',
    GreaterEqual = '>=',
    LessEqual = '<=',
    Between = 'between',

    Contains = 'contains',
    Replace = 'replace',
    ToLower = 'toLower',

    Add = 'add',
    Divide = 'divide',
    Multiply = 'multiply',
    Subtract = 'subtract',

    DateFormat = 'dateFormat',
    DistinctBy = 'distinctBy',
    PriorityBy = 'priorityBy'
}

export enum FeedUpdateInterval {
    None = 'None',
    TwentyMinute = 'TwentyMinute',
    ThirtyMinutes = 'ThirtyMinutes',
    OneHour = 'OneHour',
    ThreeHours = 'ThreeHours',
    SixHours = 'SixHours',
    TwelveHours = 'TwelveHours',
    EveryDay = 'EveryDay',
    EveryWeek = 'EveryWeek'
}

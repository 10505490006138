<mat-form-field
    appearance="outline"
    class="tw-w-full tw-max-w-xs tw-h-[57px]">
    <mat-select
        hideSingleSelectionIndicator
        [formControl]="fieldFormControlType"
        (selectionChange)="selectionChange($event.value)">
        @for (fieldType of fieldTypes; track fieldType) {
            <mat-option [value]="fieldType.value">{{ fieldType.viewValue }}</mat-option>
        }
    </mat-select>
</mat-form-field>

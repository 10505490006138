import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'card-summary',
    standalone: true,
    imports: [CommonModule, MatCardModule],
    templateUrl: './card-summary.component.html',
    styleUrl: './card-summary.component.scss'
})
export class CardSummaryComponent {}
